import React, { ReactElement, useRef } from 'react';
import { IUser } from '@models/User/i-user';
import {
  useGetCartItemCount,
  useGetMostRecentRoomForUser
} from '@components/shared/Navigation/Navigation';
import classNames from 'classnames';
import styles from './DesktopHeader.module.scss';
import LoggedOutHeader from './LoggedOutHeader/LoggedOutHeader';
import CustomerHeader from './CustomerHeader/CustomerHeader';

interface IDesktopHeaderProps {
  className?: string;
  user: IUser | null;
  isScrolled?: boolean;
  isTransparent?: boolean;
  isConflictingVariant?: boolean;
  unreadNotificationCount: number;
  hideMessaging?: boolean;
  afterLoginRedirectUrl?: string;
  showGetStarted?: boolean;
  hideNavLinks?: boolean;
  navLinksOverride?: () => ReactElement;
  cities?: string[];
}

const DesktopHeader = ({
  className = '',
  user,
  isScrolled = false,
  isTransparent = false,
  unreadNotificationCount,
  hideMessaging = false,
  showGetStarted = true,
  hideNavLinks = false,
  navLinksOverride,
  afterLoginRedirectUrl = '',
  cities
}: IDesktopHeaderProps) => {
  const hoverRef = useRef<HTMLDivElement>(null);
  const userRole = user?.role;

  const messagingRoom = useGetMostRecentRoomForUser(userRole);
  const cartItemCount = useGetCartItemCount(userRole);

  return (
    <div
      ref={hoverRef}
      className={classNames(
        className,
        styles.DesktopHeader,
        { [styles.isScrolled]: isScrolled }
      )}
    >
      {user ? (
        <CustomerHeader
          className={className}
          user={user}
          isTransparent={isTransparent}
          isScrolled={isScrolled}
          messagingRoom={messagingRoom}
          cartItemCount={cartItemCount}
          unreadNotificationCount={unreadNotificationCount}
          hideMessaging={hideMessaging}
          hideNavLinks={hideNavLinks}
          cities={cities}
          navLinksOverride={navLinksOverride}
        />

      ) : (
        <LoggedOutHeader
          showGetStarted={showGetStarted}
          cities={cities}
          hideNavLinks={hideNavLinks}
          afterLoginRedirectUrl={afterLoginRedirectUrl}
          isTransparent={isTransparent}
        />
      )}
    </div>
  );
};

export default DesktopHeader;
