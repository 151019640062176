import React from 'react';
import FacebookIcon from '@components/shared/images/atoms/FacebookIcon/FacebookIcon';
import TwitterIcon from '@components/shared/images/atoms/TwitterIcon/TwitterIcon';
import InstagramIcon from '@components/shared/images/atoms/InstagramIcon/InstagramIcon';
import PinterestIcon from '@components/shared/images/atoms/PinterestIcon/PinterestIcon';
import styles from './Social.module.scss';

const Social = () => (
  <div className={styles.Social}>
    <a
      href="https://www.facebook.com/TheHavenly"
      target="_blank"
      aria-label="Facebook"
      rel="noreferrer"
    >
      <FacebookIcon />
    </a>
    <a
      href="https://twitter.com/theHavenly"
      target="_blank"
      aria-label="Twitter"
      rel="noreferrer"
    >
      <TwitterIcon />
    </a>
    <a
      href="https://www.instagram.com/theHavenly"
      target="_blank"
      aria-label="Instagram"
      rel="noreferrer"
    >
      <InstagramIcon />
    </a>
    <a
      href="https://www.pinterest.com/thehavenly"
      target="_blank"
      aria-label="Pinterest"
      rel="noreferrer"
    >
      <PinterestIcon />
    </a>
  </div>

);

export default Social;
