import React, { useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useTheme } from '@components/theme';
import { useRouter } from 'next/router';
import { IShopNavigationMenu } from '@components/shared/shop/ShopNavigationMenu';
import { IUser } from '@models/User/i-user';
import Cart from '@components/shared/Navigation/components/Cart/Cart';
import handleNavigationClick from '@utils/navigation/handleNavigationClick';
import {
  useGetCartItemCount,
  useGetMostRecentRoomForUser
} from '@components/shared/Navigation/Navigation';
import Messaging from '@components/shared/Navigation/components/Messaging/Messaging';
import { LocalCartContext } from '@context/LocalCart/LocalCartContext';
import ShopNavigationContext from '@components/shared/shop/ShopNavigationMenu/ShopNavigationContext';
import styles from './MobileHeader.module.scss';
import HavenlyLogo from './logo.svg';
import CustomerHeader from './CustomerHeader/CustomerHeader';
import LoggedOutHeader from './LoggedOutHeader/LoggedOutHeader';
import { INavLink, shopNavLink } from '../components/NavLinks/links';

interface IMobileHeader {
  user: IUser | null;
  isScrolled: boolean;
  className?: string;
  shopNavigationMenu?: IShopNavigationMenu[];
  afterLoginRedirectUrl?: string;
  cities?: string[];
  isConflictingVariant?: boolean;
  isTransparent?: boolean;
}

const isShopPageUrl = (url: string | undefined) => {
  return !!url?.includes('/shop');
};

const MobileHeader = ({
  user,
  isScrolled,
  className = '',
  shopNavigationMenu,
  afterLoginRedirectUrl,
  cities,
  isConflictingVariant = false,
  isTransparent = true
}: IMobileHeader) => {
  const theme = useTheme();
  const router: NextRouter = useRouter();
  const { shopNavigationData } = useContext(ShopNavigationContext);
  const [searchValue, setSearchValue] = useState('');
  const [navLink, setNavLink] = useState<INavLink | undefined>(() => {
    if (shopNavigationMenu && isShopPageUrl(router.asPath)) {
      return shopNavLink(shopNavigationData);
    }
    return undefined;
  });
  const { totalCartItems } = useContext(LocalCartContext);
  const cartItemCount = useGetCartItemCount(user?.role);
  const messagingRoom = useGetMostRecentRoomForUser(user?.role);

  const isUnderShopPage = useMemo(() => isShopPageUrl(router.asPath), [router.asPath]);

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    const isOpen = !isMenuOpen;
    setMenuOpen(isOpen);

    // Block scrolling when mobile menu is open
    if (document && document.body) {
      document.body.style.overflow = 'auto';
      if (isOpen) {
        document.body.style.overflow = 'hidden';
      }
    }
  };
  const isConflictingStyle = isConflictingVariant && !isScrolled;

  return (
    <div className={classNames(
      className,
      styles.MobileHeader,
      { [styles.isOpen]: isMenuOpen },
    )}
    >
      <div className={classNames(
        styles.Header,
        styles.Container,
        { [styles.isScrolled]: isScrolled || isMenuOpen },
        { [styles.isTransparent]: isTransparent }
      )}
      >
        <a
          role="button"
          aria-label="Havenly Logo"
          className={classNames(
            styles.Logo,
            { [styles.Conflicting]: isConflictingStyle },
            { [styles.isOpen]: isMenuOpen }
          )}
          href="/"
        >
          <HavenlyLogo />
        </a>

        <div className={styles.IconContainer}>
          {user?.role === 'customer' && (
            <>
              <Messaging
                isConflictingStyle={isConflictingStyle}
                messagingRoom={messagingRoom}
                unreadNotificationCount={0}
              />

              <Cart
                isConflictingStyle={isConflictingStyle}
                cartItemCount={cartItemCount}
                isScrolled={isScrolled}
                isTransparent={false}
                onClickNavItem={(title: string) => handleNavigationClick(title)}
                skipMergeCart
              />
            </>
          )}

          {totalCartItems > 0 && !user && (
          <Cart
            isConflictingStyle={isConflictingStyle}
            cartItemCount={totalCartItems}
            onClickNavItem={(title: string) => handleNavigationClick(title)}
          />
          )}

          <button
            type="button"
            aria-label="Open Menu"
            className={classNames(styles.Hamburguer, {
              [styles.isOpen]: isMenuOpen,
              [styles.Conflicting]: isConflictingStyle
            })}
            onClick={() => toggleMenu()}
            data-test="configurable-nav__toggle-mobile"
          >
            <div className={styles.Line} />
            <div className={styles.Line} />
            <div className={styles.Line} />
          </button>
        </div>
      </div>
      {
        <nav
          className={classNames(
            styles.Nav,
            { [styles.isOpen]: isMenuOpen }
          )}
          data-test="nav-mobile"
        >
          <div className={classNames(theme.styles.Container, styles.Content)}>
            {user ? (
              <CustomerHeader
                isUnderShopPage={isUnderShopPage}
                shopNavigationMenu={shopNavigationMenu}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                toggleMenu={toggleMenu}
                navLink={navLink}
                setNavLink={setNavLink}
                cities={cities}
              />
            ) : (
              <LoggedOutHeader
                isUnderShopPage={isUnderShopPage}
                shopNavigationMenu={shopNavigationMenu}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                toggleMenu={toggleMenu}
                navLink={navLink}
                afterLoginRedirectUrl={afterLoginRedirectUrl}
                setNavLink={setNavLink}
                cities={cities}
              />
            )}

          </div>
        </nav>
      }
    </div>
  );
};

export default MobileHeader;
