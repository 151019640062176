import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { IShopNavigationMenu } from '@components/shared/shop/ShopNavigationMenu';
import SearchBar from '@components/shared/Search/SearchBar/SearchBar';
import { useTheme } from '@components/theme';
import analyticsTrack from '@lib/analytics/analyticsTrack';
import { useRouter } from 'next/router';
import { mapValues } from 'lodash';
import { SHOP_SEARCH_URL } from '@components/pages/ShopV3/util/search';
import ShopNavigationContext from '@components/shared/shop/ShopNavigationMenu/ShopNavigationContext';
import TrackingEvents from '@constants/tracking/events';
import { customerLinks, INavLink } from '../../components/NavLinks/links';
import NavLinks from '../../components/NavLinks/MobileView/NavLinks';
import styles from './CustomerHeader.module.scss';
import Social from '../../components/Social/Social';

interface ICustomerHeaderProps {
  className?: string;
  isUnderShopPage: boolean;
  shopNavigationMenu?: IShopNavigationMenu[];
  searchValue: string;
  navLink?: INavLink;
  setSearchValue: (text: string) => void;
  toggleMenu: () => void;
  setNavLink: (navLink?: INavLink) => void;
  cities?: string[];
}

const CustomerHeader = ({
  className,
  isUnderShopPage,
  shopNavigationMenu,
  searchValue,
  setSearchValue,
  toggleMenu,
  navLink,
  setNavLink,
}: ICustomerHeaderProps) => {
  const theme = useTheme();
  const router: NextRouter = useRouter();
  const { shopNavigationData } = useContext(ShopNavigationContext);

  const [openTabs, setOpenTabs] = useState(
    () => customerLinks(shopNavigationData).reduce(
      (result: Record<string, boolean>, link: any) => {
        return {
          ...result,
          [link.title]: false
        };
      }, {}
    )
  );

  const handleTabClick = (link: INavLink) => {
    if (isUnderShopPage && link.isPaginated) {
      setNavLink(link);
    } else {
      setOpenTabs(mapValues(openTabs, (value, key) => {
        if (link.title === key) return !value;
        return false;
      }));
    }
  };

  if (navLink && navLink.renderSubPage) {
    if (isUnderShopPage) {
      return navLink.renderSubPage({
        shopNavigationMenu,
        handleGoBack: () => setNavLink(undefined)
      });
    }

    return navLink.renderSubPage({
      handleGoBack: () => setNavLink(undefined)
    });
  }

  return (
    <nav className={classNames(
      className,
      styles.CustomerHeader
    )}
    >
      <NavLinks
        setNavLink={setNavLink}
        navLinks={customerLinks(shopNavigationData)}
        openTabs={openTabs}
        hasSubNav={isUnderShopPage}
        handleTabClick={handleTabClick}
      />
      {isUnderShopPage && (
        <SearchBar
          className={classNames(
            theme.styles.Title2,
            theme.styles.Core4,
            styles.SearchWrapper,
            { [styles.ShowCloseIcon]: searchValue.length > 0 }
          )}
          text={searchValue}
          onChange={(value) => setSearchValue(value)}
          onClose={() => setSearchValue('')}
          onSubmit={async (e) => {
            e.preventDefault();
            const text = searchValue?.trim();
            analyticsTrack(TrackingEvents.SHOP_NAV_SEARCHED, {
              title: `Shop Navigation Menu | Search`,
              searchedText: text,
            });
            router.push({
              pathname: SHOP_SEARCH_URL,
              query: {
                text
              }
            });
            toggleMenu();
            setSearchValue('');
          }}
        />
      )}
      <Social />
    </nav>
  );
};

export default CustomerHeader;
