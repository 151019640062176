/* eslint-disable camelcase */

import { Brand, Maybe } from '@codegen-basetypes';

enum TrackingEvents {
  MERCH_ORDER_COMPLETED_FE = 'merch_order_completed_fe',
  ONBOARDING_QUESTION = 'onboarding_question',
  ONBOARDING_STYLES_SELECTED = 'onboarding_styles_selected',
  ONBOARDING_QUIZ_STARTED = 'onboarding_quiz_started',
  ONBOARDING_ROOMS_SELECTED = 'onboarding_rooms_selected',
  ONBOARDING_ROOM_PRIORITY = 'onboarding_room_priority',
  DESIGNER_INFO = 'designer_info',
  DESIGNER_SELECT = 'designer_select',
  PACKAGE_QUANTITY_UPDATED = 'package_quantity_updated',
  PACKAGE_PROMO_ADDED = 'package_promo_added',
  SHOP_NAV_SEARCHED = 'shop_nav_searched'
}

interface Products {
  availability?: Maybe<string>;
  brand?: Maybe<Brand>;
  cart_quantity: number;
  category: string | null;
  image_url: string | null;
  list_position: number;
  name: string;
  price?: Maybe<number>;
  product_id: string;
  product_name: string;
  quantity: number;
  sale_price?: Maybe<number>;
  sku?: Maybe<string>;
  taxonomy?: Maybe<string>;
  vendor_name: string;
  vendor_variant_id: string;
}

export interface MerchOrderCompletedTrackingEventSchema {
  cart_id: string;
  youSave: number;
  products: Products[];
  product_ids?: (string | undefined)[];
  revenue: number;
  shipping: number;
  tax: number;
}

export interface DesignerSelectEventSchema {
  availability: number;
  designer_name: string;
  designer_id: number | string;
  designer_image: string;
  list_id: string;
  position: number;
}

export default TrackingEvents;
